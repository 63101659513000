import '../styles/thanks.scss';
import axios from "axios";
import { useEffect } from "react";


export default function ThankYou() {
  const url = 'https://dev.pmwwebdesign.com'
  // const url = 'http://localhost:3999'



  useEffect(() => {
    let details = localStorage.getItem('client')
    if (details) {
      let finalDetails = JSON.parse(details)
      sendEmail(finalDetails)
    }
  }, [])

  const sendEmail = async (details) => {
    axios.post(`${url}/psdt/admin-email`, details)
      .then((res) => {
        console.log(res.data)
        localStorage.clear()
      }).catch((err) => {
        console.log(err)
      })

  }


  return (
    <main className="thank__you">
      <section className="thanks">
        <h2>Thank You</h2>
        <h4>We have your details and you will soon receive an email to join the online community</h4>
      </section>
    </main>
  )
}