import { Elements, } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState, useEffect } from "react";
import Alert from '@mui/material/Alert';
import axios from 'axios';
import CheckoutForm from './CheckOutForm';

const stripe = await loadStripe('pk_live_aRr3qYrpVTbyNgBbB7t0jqiy');



export default function PayForm(props) {
  // alerts
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [showAlert, setShowAlert] = useState(false)
  const [userDetails, setUserDetails] = useState({});
  const [secret, setSecret] = useState('');
  const [finalPrice, setFinalPrice] = useState(3000);
  const [options, setOptions] = useState({});
  const [code, setCode] = useState('')
  const [loader, setloader] = useState(true);
  const [email, setEmail] = useState('');

  const url = 'https://dev.pmwwebdesign.com'


  useEffect(() => {
    let secret;

    if (props.evergreen) {
      let amount = {
        amount: 360000
      }
      axios.post(`${url}/psdt/secret`, amount).then((res) => {
        setSecret(res.data.client_secret)
        secret = res.data.client_secret
        setOptions({ clientSecret: secret, })
        setloader(false)
      }).catch((err) => {
        console.log(err)
      })
    } else {
      let amount = {
        amount: 299640
        // amount: 70
      }
      axios.post(`${url}/psdt/secret`, amount).then((res) => {
        setSecret(res.data.client_secret)
        secret = res.data.client_secret
        setOptions({ clientSecret: secret, })
        setloader(false)
      }).catch((err) => {
        console.log(err)
      })
    }
  }, [])

  const handleDetails = (value) => {
    setEmail(value)
  } 

  const handleDisCountCode = (dis) => {
    let discount = dis
    setCode(discount)

    console.log(discount)

    if (discount.toLowerCase() === 'pmwtribe') {
      setFinalPrice(2497)
    }
  }

  const manageError = (error) => {
    setMessage(error)
    setSeverity('error')
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 2500)
  };
  


  return (
    <main className="payform">
      <h2>Register Now</h2>
      <section className="billing__details">
      <h6 className="price">Total Price: £{finalPrice} + VAT</h6>

        <section className="form--row">
          <div>
            <label for="emailI">Email <span id="email">Required field</span></label>
            <input type="email" id="emailI" onChange={(e) => handleDetails(e.target.value)} />
          </div>
        </section>
        {props.evergreen ? (
          <div className='discount--code'>
            <label for='discountcode'>Discount Code </label>
            <input type="text" id='discountcode' onChange={(e) => handleDisCountCode(e.target.value)} />
          </div>
        ) : null}

        {loader ? (
          null
        ) : (
          <Elements stripe={stripe} options={options}>
            <CheckoutForm error={manageError} />
          </Elements>
        )}

      </section>


      {showAlert ? (
        <Alert severity={severity} sx={{ margin: '1% auto', width: '80%' }} variant="filled">{message}</Alert>
      ) : null}


    </main>
  )

}