import './styles/navFooter.scss';
import logo from './images/WhiteWings.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { AiFillTikTok, AiFillInstagram } from "react-icons/ai";
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useEffect, useState } from 'react';

export default function Footer () {
  const [year, setYear] = useState('')

  useEffect(() => {
    const now = new Date();
    let year = now.getFullYear()
    setYear(year)
  },[])

   return(
    <footer>
      <img src={logo} alt="pmw logo" />

      <section className='socials'>
        <FacebookIcon className="social--icon"/>
        <AiFillInstagram className="social--icon"/>
        <LinkedInIcon className="social--icon"/>
        <YouTubeIcon className="social--icon"/>
        <AiFillTikTok className="social--icon"/>
      </section>
      <hr/>
      <h6>PAUL MCFADDEN WEALTH LTD. COPYRIGHT {year} <CopyrightIcon /> ALL RIGHTS RESERVED</h6>
    </footer>
   )
}