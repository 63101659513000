import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import ThankYou from './components/sections/ThankYou';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header />
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route path='thank-you' element={<ThankYou />}/>
          <Route path="*" element={<ThankYou />} />
        </Routes>
      
      </BrowserRouter>
      <Footer />

    </div>
  );
}

export default App;
