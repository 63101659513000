import { useState, useEffect } from "react";
import './styles/home.scss';
import PayForm from "./sections/PayForm";
import Alert from '@mui/material/Alert';


export default function Home() {
  const [evergreen, setEvergreen] = useState(true);
  const [showTime, setShowTime] = useState("");
  const [copyAlert, setCopyAlert] = useState(false);

  const copiedAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }


  useEffect(() => {
    const today = new Date();
    const endOffer = new Date('2024-08-07 20:00:00')

    if (today < endOffer) {
      setEvergreen(false)
    }
  }, [])

  const calculateTimeLeft = () => {
    let event = new Date('2024-08-07 20:00:00').getTime();
    let now = new Date().getTime();

    let distance = event - now;

    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return `${days}day, ${hours}h, ${minutes}min, ${seconds}secs`;
  };

  useEffect(() => {
    setTimeout(() => {
      setShowTime(calculateTimeLeft);
    }, 1000);
  },);

  const copy = (code) => {
    navigator.clipboard.writeText(code)
    setCopyAlert(true)
    setTimeout(() => {
      setCopyAlert(false)
    }, 1000)
  }

  return (
    <main className="main__home">
      <section className="hero">
        <h1>Serviced Accommodation masterclass</h1>
        {evergreen ? (
          <p>Register now to our online course for £3000 + VAT</p>
        ) : (
          <p>Access the module at a unique reduced price, this offer is on a time limit, use the code <strong onClick={() => copy("PMWTRIBE")}>PMWTRIBE</strong> to access the course for £2497 + VAT</p>
        )}
      </section>
      {/* <section className="learn">
        <h2>What you are going to learn</h2>
        <hr />
        <section className="learn__row">
          <AddTaskIcon className="learn--icon" />
          <h4>Statistics</h4>
        </section>
        <section className="learn__row">
          <AddTaskIcon className="learn--icon" />
          <h4>Overview of Serviced Accomodation</h4>
        </section>
        <section className="learn__row">
          <AddTaskIcon className="learn--icon" />
          <h4>Legilation</h4>
        </section>
        <section className="learn__row">
          <AddTaskIcon className="learn--icon" />
          <h4>Target Markets</h4>
        </section>
        <section className="learn__row">
          <AddTaskIcon className="learn--icon" />
          <h4>Due Diligence</h4>
        </section>
        <section className="learn__row">
          <AddTaskIcon className="learn--icon" />
          <h4>Dinamyc Pricing Software</h4>
        </section>
        <section className="learn__row">
          <AddTaskIcon className="learn--icon" />
          <h4>Online Travel Agents</h4>
        </section>
        <section className="learn__row">
          <AddTaskIcon className="learn--icon" />
          <h4>Channel Manager</h4>
        </section>
        <section className="learn__row">
          <AddTaskIcon className="learn--icon" />
          <h4>Guest Communication</h4>
        </section>
        <section className="learn__row">
          <AddTaskIcon className="learn--icon" />
          <h4>Guest Access</h4>
        </section>
        <section className="learn__row">
          <AddTaskIcon className="learn--icon" />
          <h4>Cleaning & House Keeping</h4>
        </section>
      </section> */}
      <section className='timer'>
        {evergreen ? (
          <h2>Join us now</h2>
        ) : (
          <>
            <h2>Don't run out of time</h2>
            <p className='countdown'>{showTime}</p>
            <p>Use Code: <strong onClick={() => copy("PMWTRIBE")}>PMWTRIBE</strong></p>
          </>
        )}
      </section>
      {copyAlert ? (
        <Alert severity='primary' sx={copiedAlert} >
          Copied to clipboard
        </Alert>
      ) : null}

      <PayForm evergree={evergreen} />


    </main>
  )
}